import React, { useEffect, useState } from "react";
import poweredByWorkplay from "../assets/powered-by-workplay.png";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Game.css";
import "../styles/Result.css";
import axios from "axios";

const { REACT_APP_LOGO_URL, REACT_APP_CONTACT_URL, REACT_APP_API_URL } =
  process.env;

const Result: React.FC = () => {
  const navigate = useNavigate();
  const score = Number(localStorage.getItem("gameScore"));
  const questionlength = Number(localStorage.getItem("totalQuestions"));
  const gameScore = localStorage.getItem("gameScore");
  const [showResult, setShowResult] = useState(false);

  // Store the score and total questions in local storage
  useEffect(() => {
    // Store the score and total questions in local storage

    setTimeout(() => {
      setShowResult(true);
    }, 200);

    // Send data to the backend when this component is mounted
    sendDataToBackend();
  }, []);

  const handleContact = () => {
    window.open(`${REACT_APP_CONTACT_URL}`, "_blank");
  };

  const handleRestart = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleLeaderboard = () => {
    // Fetch leaderboard data from the backend when "leaderboard" is clicked
    axios
      .get(`${REACT_APP_API_URL}/quiz-result`)
      .then((response: any) => {
        const leaderboardData = response.data;
        // You can do something with the leaderboard data if needed
        navigate("/leaderboard"); // Navigate to the leaderboard page
      })
      .catch((error: any) => {
        console.error("Error fetching leaderboard data:", error);
      });
  };

  const sendDataToBackend = () => {
    // Check if data has already been sent
    const dataSentFlag = localStorage.getItem("dataSentFlag");

    if (!dataSentFlag) {
      const gameScore = localStorage.getItem("gameScore");
      const totalQuestions = localStorage.getItem("totalQuestions");
      const username = localStorage.getItem("userName");
      const totalTimeUsed = localStorage.getItem("totalTimeUsed");

      if (gameScore && totalQuestions && username && totalTimeUsed) {
        const data = {
          username: username,
          score: gameScore,
          totalTime: totalTimeUsed,
          timestamp: new Date().toISOString(),
        };

        // Send the data to the backend
        axios
          .post(`${REACT_APP_API_URL}/quiz-result`, data)
          .then((response) => {
            console.log("Data sent to the backend:", response.data);
            // Set the flag to indicate that data has been sent
            localStorage.setItem("dataSentFlag", "true");
          })
          .catch((error) => {
            console.error("Error sending data to the backend:", error);
          });
      } else {
        console.error("Data not available in localStorage");
      }
    } else {
      console.log("Data already sent to the backend");
    }
  };

  return (
    <section>
      <div
        className={`result-container ${
          showResult ? "visible" : ""
        } flex flex-col justify-center items-center  py-12 px-4 `}
      >
        {score === questionlength * 20 ? (
          <>
            <h3 className="result font-bold">Congratulations! You Won!</h3>
          </>
        ) : (
          <>
            <h3 className="result">Game Over</h3>
          </>
        )}
        <p>Your final score is {gameScore}</p>

        <p>If you like this marketing game get in touch with us!</p>
        <div className="buttons">
          <button onClick={handleRestart}>Play Again</button>
          <button onClick={handleLeaderboard}>leaderboard</button>
          <button onClick={handleContact}>Contact Us</button>
        </div>
        <Link to={`${REACT_APP_LOGO_URL}`} target="_blank">
          <img
            src={poweredByWorkplay}
            className="h-16 lg:h-20"
            alt="powered by WorkPlay"
          />
        </Link>
      </div>
    </section>
  );
};

export default Result;
