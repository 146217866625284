import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/Leaderboard.css";
import { Link } from "react-router-dom";
import poweredByWorkplay from "../assets/powered-by-workplay.png";

const { REACT_APP_LOGO_URL, REACT_APP_API_URL } = process.env;

interface LeaderboardEntry {
  _id: string;
  username: string;
  score: number;
  timestamp: string;
  totalTime: number;
}

const Leaderboard: React.FC = () => {
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>(
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLeaderboard(true);
    }, 300);
  }, []);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/quiz-result`)
      .then((response:any) => {
        // Sort the leaderboard data
        const sortedData = response.data.sort(
          (a: LeaderboardEntry, b: LeaderboardEntry) => {
            if (a.score !== b.score) {
              // Compare by score in descending order
              return b.score - a.score;
            } else {
              // If scores are the same, compare by totalTime in ascending order
              return a.totalTime - b.totalTime;
            }
          }
        );

        setLeaderboardData(sortedData);
      })
      .catch((error:any) => {
        console.error("Error fetching leaderboard data:", error);
      });
  }, []);

  return (
    <section id="leaderboard">
      <div
        className={`leaderboard-container ${showLeaderboard ? "visible" : ""} flex flex-col justify-center items-center`}
      >
        <h1 className=" text-xl lg:text-2xl font-extrabold">Leaderboard</h1>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.slice(0, 10).map((entry, index) => (
              <tr key={entry._id}>
                <td>{index + 1}</td>
                <td>{entry.username}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to={`${REACT_APP_LOGO_URL}`} target="_blank">
        <img src={poweredByWorkplay}  className="h-16 lg:h-20" alt="powered by WorkPlay" />
        </Link>
      </div>
    </section>
  );
};

export default Leaderboard;
