import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import workplayLogo from "../assets/workplay-logo.png";
import "../styles/Home.css";

const { REACT_APP_LOGO_URL } = process.env;

const Home: React.FC = () => {
  const [showHome, setShowHome] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(""); // State to manage the input value

  useEffect(() => {
    // Check if the user's name is already stored in local storage
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      setInputValue(""); // Clear the input field if not found in local storage
    }

    setTimeout(() => {
      setShowHome(true);
    }, 100);
  }, []);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    // Remove any non-alphabet characters using a regular expression
    value = value.replace(/[^a-zA-Z]+/g, "");
    // Capitalize the first letter
    value = value.charAt(0).toUpperCase() + value.slice(1);

    setInputValue(value);
  };

  const handleStartGame = () => {
    if (inputValue.trim() === "") {
      alert("Please enter your name.");
    } else {
      // Store the user's name in local storage
      localStorage.setItem("userName", inputValue);

      // Clear the input field
      setInputValue("");

      console.log("User name:", inputValue);

      // Check if the user's name is in local storage before navigating
      if (localStorage.getItem("userName")) {
        navigate("/game");
      } else {
        alert("Please enter your name.");
      }
    }
  };

  return (
    <section>
      <div
        className={`home-container ${
          showHome ? "visible" : ""
        } flex flex-col justify-center items-center  py-16`}
      >
        <Link to={`${REACT_APP_LOGO_URL}`} target="_blank">
          <img
            className="h-35 mb-4 lg:h-10"
            src={workplayLogo}
            alt="WorkPlay Logo"
          />
        </Link>
        <h2>quiz</h2>
        <p>Explore the advantages of gamification!</p>
        <input
          type="text"
          placeholder="Enter your name"
          value={inputValue}
          onChange={handleNameChange}
        />
        <button className="HomeBtn" onClick={handleStartGame}>
          Game on
        </button>
      </div>
    </section>
  );
};

export default Home;
