import React from "react";
import workplayLogo from "../assets/workplay-logo.png";
import timer from "../assets/timer.png";
import { Link } from "react-router-dom";
import "../styles/Questions.css";

const { REACT_APP_LOGO_URL } = process.env;

interface Question {
  question: string;
  options: Option[];
  answer: string;
}

interface Option {
  options: string;
  isCorrect: boolean;
  isWrong: boolean;
}

interface QuestionProps {
  currentQuestion: Question | null;
  currentQuestionIndex: number;
  selectedOption: string | null;
  remainingTimer: number;
  score: number;
  handleOptionClick: (selectedOption: string) => void;
  showScore: boolean;
  questionsLength: number;
}

const Questions: React.FC<QuestionProps> = ({
  currentQuestion,
  selectedOption,
  remainingTimer,
  currentQuestionIndex,
  score,
  handleOptionClick,
  showScore,
  questionsLength,
}) => {
  if (!currentQuestion) {
    return null;
  }

  return (
    <>
      <Link to={`${REACT_APP_LOGO_URL}`} target="_blank">
        <img
          className="h-35 lg:h-10 mb-4"
          src={workplayLogo}
          alt="WorkPlay Logo"
        />
      </Link>
      <div className=" flex flex-row gap-x-4">
        <img src={timer} className="h-7 lg:h-8 " alt="Timer" />
        <p>0 : {remainingTimer < 10 ? `0${remainingTimer}` : remainingTimer}</p>
      </div>
      <div className="question-container flex flex-col justify-center items-center mx-5">
        <h3 className="text-start text-l lg:text-xl">
          {currentQuestionIndex + 1} . {currentQuestion.question}
        </h3>
      </div>
      <div className="options">
        {currentQuestion.options.map((option) => (
          <div
            key={option.options}
            className={`option ${
              option.isCorrect ? "correct" : option.isWrong ? "wrong" : ""
            } ${selectedOption === option.options ? "selected" : ""}`}
            onClick={() => handleOptionClick(option.options)}
          >
            <p className="option-container">{option.options}</p>
          </div>
        ))}
      </div>
      <div className={`score ${showScore ? "visible" : ""}`}>
        <span>Score</span>
        <p className="scoretext">{score}</p>
      </div>
    </>
  );
};

export default Questions;
