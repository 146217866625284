import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Questions from "../components/Questions";
import Result from "../components/Result";
import "../styles/Game.css";
import "../styles/Result.css";

interface Question {
  question: string;
  options: Option[];
  answer: string;
}

interface Option {
  options: string;
  isCorrect: boolean;
  isWrong: boolean;
}

const Game: React.FC = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [totalTimer, setTotalTimer] = useState<number>(30);
  const [remainingTimer, setRemainingTimer] = useState<number>(30);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showGame, setShowGame] = useState(false);
  const [showScore, setShowScore] = useState(false);
  const [totalTimeUsed, setTotalTimeUsed] = useState<number>(0);
  localStorage.setItem("gameScore", score.toString());

  useEffect(() => {
    // Fetch the quiz questions
    fetch("/QuizQuestions.json")
      .then((response) => response.json())
      .then((data) => {
        // Shuffle the questions here
        const shuffledQuestions = shuffleArray(data.questions);
        setQuestions(shuffledQuestions);
      })
      .catch((error) => console.error("Error fetching quiz questions:", error));
  }, []);

  // Function to shuffle questions
  const shuffleArray = (array: Question[]) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  useEffect(() => {
    if (!gameOver && remainingTimer > 0) {
      const timerId = setInterval(() => {
        setRemainingTimer((prevRemainingTimer) => prevRemainingTimer - 1);

        // Increment total time and update local storage
        setTotalTimeUsed((prevTotalTimeUsed) => prevTotalTimeUsed + 1);
        localStorage.setItem("totalTimeUsed", (totalTimeUsed + 1).toString());
      }, 1000);

      return () => clearInterval(timerId);
    } else if (remainingTimer === 0 && !gameOver) {
      handleTimerExpired();
    }
  }, [remainingTimer, gameOver]);

  useEffect(() => {
    // Simulate a delay before showing the game container
    setTimeout(() => {
      setShowGame(true);
    }, 200);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowScore(true);
    }, 500);
  }, []);

  const moveToNextQuestion = () => {
    setSelectedOption(null);
    setTimerExpired(false);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setRemainingTimer(totalTimer); // Reset timer for the next question
    } else {
      handleGameOver();
    }
  };

  const handleOptionClick = (selectedOption: string) => {
    if (gameOver) {
      return;
    }

    const currentQuestion = questions[currentQuestionIndex];
    setSelectedOption(selectedOption);

    // Check if the selected option is correct
    const isCorrect = selectedOption === currentQuestion.answer;

    // Update the options to indicate correct and incorrect ones
    const updatedOptions = currentQuestion.options.map((option) => ({
      ...option,
      isCorrect: option.options === currentQuestion.answer,
      isWrong: option.options !== currentQuestion.answer,
    }));

    // Update the question with the new options
    questions[currentQuestionIndex].options = updatedOptions;

    // Update the score for the current question
    if (isCorrect) {
      const updatedScore = score + 20;
      setScore(updatedScore);
      // Store the score in local storage for this specific question
      localStorage.setItem("gameScore", updatedScore.toString());
    }

    // Move to the next question after a delay
    setTimeout(moveToNextQuestion, 1000);
  };

  const handleTimerExpired = () => {
    setTimerExpired(true);
    setShowPopup(true);

    // Show the overlay
    setShowOverlay(true);
  };

  const handleRetry = () => {
    setShowPopup(false);
    setSelectedOption(null);
    setCurrentQuestionIndex(0); // Reset to the first question
    setScore(0);
    setGameOver(false);
    setRemainingTimer(totalTimer);
    setTimerExpired(false);

    // Reset the border colors for all options
    const updatedQuestions = questions.map((question) => ({
      ...question,
      options: question.options.map((option) => ({
        ...option,
        isCorrect: false,
        isWrong: false,
      })),
    }));

    setQuestions(updatedQuestions);
    // Hide the overlay when retrying
    setShowOverlay(false);
    localStorage.clear();
    navigate("/");
  };

  const handleGameOver = () => {
    // Store the total number of questions in local storage
    localStorage.setItem("totalQuestions", questions.length.toString());

    navigate("/result");
    setGameOver(true);
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setGameOver(false);
    setSelectedOption(null);
    setRemainingTimer(totalTimer);
    setTimerExpired(false);
    setShowPopup(false);

    // Reset the border colors for all options
    const updatedQuestions = questions.map((question) => ({
      ...question,
      options: question.options.map((option) => ({
        ...option,
        isCorrect: false,
        isWrong: false,
      })),
    }));

    setQuestions(updatedQuestions);
    localStorage.clear();
    navigate("/");
  };

  // Destructure current question
  const currentQuestion = questions[currentQuestionIndex];
  const questionsLength = questions.length;

  return (
    <section>
      <div
        className={`game-container ${
          showGame ? "visible" : ""
        } flex flex-col justify-center items-center py-4 lg:py-10`}
      >
        {gameOver ? (
          <Result />
        ) : (
          <Questions
            currentQuestion={currentQuestion}
            currentQuestionIndex={currentQuestionIndex}
            selectedOption={selectedOption}
            remainingTimer={remainingTimer}
            score={score}
            handleOptionClick={handleOptionClick}
            showScore={showScore}
            questionsLength={questionsLength}
          />
        )}
      </div>
      {showPopup && (
        <div className="popup">
          <p>Time's Up!</p>
          <button onClick={handleRetry}>Retry</button>
        </div>
      )}
      <div className={`overlay ${showOverlay ? "visible" : ""}`}></div>
    </section>
  );
};

export default Game;
